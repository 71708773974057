import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cartForm', 'currentPreview']

  connect() {
    // INITIALIZATION OF DROPZONE
    // =======================================================
    this.handleDragAndDropDocument()
    this.cartFormTarget.addEventListener('submit', this.submitForm.bind(this))
  }

  handleDragAndDropDocument() {
    var self = this

    HSCore.components.HSDropzone.init('.js-dropzone', {
      autoProcessQueue: false,
      uploadMultiple: false,
      binaryBody: true,
      parallelUploads: 999,
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      maxFiles: 10,
      acceptedFiles: '.pdf, .jpg, .jpeg, .png, .xls, .xlsx',
      accept: function (file, done) {
        self.handlePDFPreview(file)

        const prefix = 'relationship_documents/'
        const file_name = encodeURIComponent(file.name)
        const file_type = encodeURIComponent(file.type)

        fetch(
          `/uploader/presign_s3/?file_name=${file_name}&file_type=${file_type}&prefix=${prefix}`,
        )
          .then((response) => response.json())
          .then((data) => {
            file.uploadURL = data.presigned_url
            file.key = data.key
            done()
          })
      },
      sending: function (file, xhr, formData) {
        // Overwrite Dropzone's default upload URL with our pre-signed URL
        xhr.open('PUT', file.uploadURL, true)

        // Send file as binary data
        var _send = xhr.send
        xhr.send = function () {
          _send.call(xhr, file)
        }
      },
      complete: function (file) {
        // submit form if all files are uploaded
        if (!self.areAllFilesUploaded(this)) return

        const files = self.dropzone.files.map((file) => ({
          key: file.key,
          name: file.name,
        }))

        const inputElement = document.createElement('input')
        inputElement.type = 'hidden'
        inputElement.name = 'files'
        inputElement.value = JSON.stringify(files)

        self.cartFormTarget.appendChild(inputElement)

        self.formReady = true
        self.cartFormTarget.submit.disabled = false
        self.cartFormTarget.submit.click()
      },
      removedfile: function (file) {
        if (
          file.previewElement != null &&
          file.previewElement.parentNode != null
        ) {
          file.previewElement.parentNode.removeChild(file.previewElement)
        }

        this.element.querySelector('.dz-message').classList.remove('d-none')
        return this._updateMaxFilesReachedClass()
      },
    })
  }

  areAllFilesUploaded(dropzone) {
    if (dropzone.getQueuedFiles().length > 0) {
      return false
    }

    if (dropzone.getUploadingFiles().length > 0) {
      return false
    }

    return true
  }

  submitForm(event) {
    if (this.formReady) return

    event.preventDefault()

    this.dropzone = Dropzone.forElement('.js-dropzone')

    if (this.dropzone.files.length == 0) {
      alert('No document selected. Please upload at least one.')
      return
    }
    this.cartFormTarget.submit.disabled = true

    if (!this.areAllFilesUploaded(this.dropzone)) {
      // submit uploading files
      this.dropzone.processQueue()
      return
    }
  }

  handlePDFPreview(file) {
    if (!file.type.startsWith('application/pdf')) return

    const reader = new FileReader()
    reader.onload = function (event) {
      const blob = new Blob([event.target.result], { type: file.type })
      const blobUrl = URL.createObjectURL(blob)

      // Display the Blob using the blobUrl
      const previewer = document.createElement('embed')
      previewer.src = blobUrl + '#toolbar=0'
      previewer.type = 'application/pdf'
      previewer.classList.add('file-preview')

      const dzImgElement = file.previewElement.querySelector('.dz-img')
      if (dzImgElement) {
        dzImgElement.appendChild(previewer)
      }
    }
    reader.readAsArrayBuffer(file)
  }
}
